import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, NgFor, UpperCasePipe } from '@angular/common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApplyCouponCodeDocument, RemoveCouponCodeDocument } from '../../../common/gql/graphql';
import { assertNever } from '../../../common/utils/assert-never';
import { DataService } from '../../../core/providers/data/data.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';

@Component({
    selector: 'kb-cart-coupons',
    templateUrl: './cart-coupons.component.html',
    styleUrls: ['./cart-coupons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, FormsModule, NgIf, NgFor, FontAwesomeModule, UpperCasePipe],
})
export class CartCouponsComponent {
    @Input() activeCouponCodes: string[] = [];
    couponCode = '';
    displayCouponCodeInput = false;
    displayGiftCardWarning = false;
    times = faTimes;

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    applyCouponCode(codeInput: string) {
        const code = codeInput.toLocaleUpperCase();
        this.dataService
            .mutate(ApplyCouponCodeDocument, {
                code,
            })
            .subscribe({
                next: ({ applyCouponCode }) => {
                    switch (applyCouponCode.__typename) {
                        case 'Order':
                            this.notificationService
                                .success(`Applying coupon "${code}" to your order`)
                                .subscribe();
                            this.couponCode = '';
                            this.displayCouponCodeInput = false;
                            break;
                        case 'CouponCodeInvalidError':
                        case 'CouponCodeExpiredError':
                        case 'CouponCodeLimitError':
                            this.notificationService.error(applyCouponCode.message).subscribe();
                            if (this.userPossiblyEnteredGiftCard(code)) {
                                this.displayGiftCardWarning = true;
                            }
                            break;
                        default:
                            assertNever(applyCouponCode);
                    }

                    this.changeDetector.markForCheck();
                },
                error: (err) => {
                    this.notificationService.error(`Discount code "${code}" is not valid`).subscribe();
                },
            });
    }

    userPossiblyEnteredGiftCard(code: string): boolean {
        return /^[ABCDEFGHJKLMNPQRSTUVWXYZ123456789]{6}$/i.test(code);
    }

    removeCouponCode(code: string) {
        this.dataService
            .mutate(RemoveCouponCodeDocument, {
                code,
            })
            .subscribe();
    }
}
