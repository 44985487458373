<ng-container *ngIf="freeShippingStatus$ | async as status">
    <div *ngIf="status && status.qualifiesForFreeShipping && 0 < status.amountUntilFreeShipping" class="">
        <div class="text-sm text-plantation bg-primary-50 border border-primary-100 p-4 rounded text-center">
            Spend an extra <span class="font-bold">{{ status.amountUntilFreeShipping | formatPrice }}</span>
            <span *ngIf="status.freeShippingRestriction"> on {{ status.freeShippingRestriction }}</span>
            for
            <a [routerLink]="status.routerLink" class="text-link"
                >free shipping to {{ status.region }}</a
            >
        </div>
    </div>
</ng-container>
