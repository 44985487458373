<button
    class="text-link underline"
    [ngClass]="displayCouponCodeInput || activeCouponCodes?.length ? 'hidden' : 'block'"
    (click)="displayCouponCodeInput = true"
>
    Enter a discount code
</button>
<div class="flex" [ngClass]="displayCouponCodeInput ? 'block' : 'hidden'">
    <input
        type="text"
        class="input rounded-r-none uppercase"
        [(ngModel)]="couponCode"
        (keydown.enter)="applyCouponCode(couponCode)"
    />
    <button
        class="btn py-0 rounded-l-none md:whitespace-nowrap"
        type="button"
        [disabled]="!couponCode"
        (click)="applyCouponCode(couponCode)"
    >
        Apply code
    </button>
</div>
<div *ngIf="displayGiftCardWarning" class="max-w-xs mt-4 text-yellow-600 text-sm">
    Note that <strong class="font-bold">gift vouchers</strong> may be used during the checkout process.
</div>
<div class="active-codes" *ngIf="activeCouponCodes?.length">
    <span
        *ngFor="let code of activeCouponCodes"
        class="inline-flex rounded-lg items-center py-2 pl-2.5 pr-1 text-sm bg-green-100 text-green-700"
    >
        <div>
            <div class="opacity-60 text-sm font-normal">Discount code</div>
            <div class="text-lg font-medium -mt-2">{{ code | uppercase }}</div>
        </div>
        <button
            type="button"
            (click)="removeCouponCode(code)"
            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-green-400 hover:bg-green-200 hover:text-green-500 focus:outline-none focus:bg-green-500 focus:text-white"
        >
            <span class="sr-only">Remove large option</span>
            <fa-icon [icon]="times"></fa-icon>
        </button>
    </span>
</div>
